*,
*::before,
*::after {
    box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Poppins', sans-serif !important;
}

.main {
    display: flex;
    flex-wrap: wrap;

    .side {
        width: 234px;
        background-color: #ffffff;
        transition: all ease 0.3s;
        overflow: hidden;
        position: sticky;
        left: 0;
        top: 59px;
        height: calc(100vh - 59px);
        z-index: 11;

        @media only screen and (max-width: 767px) {
            position: fixed;
            left: 0;
            transform: translateX(-100%);
            top: 72px;
            height: calc(100vh - 72px);
            display: none;
        }

    }

    #map {
        height: calc(75vh - 100px);
        width: 100%;
    }

    .side.hide_side {
        width: 0;
        padding: 0;

        @media only screen and (max-width: 767px) {
            width: 234px;
            padding: 0;
            transform: translateX(0%);
        }
    }

    .content {
        width: calc(100% - 234px);
        background-color: #f2f5fa;
        border-radius: 10px 0 0 0;
        padding: 15px 20px 20px 20px;
        transition: all ease 0.3s;

        @media only screen and (max-width: 767px) {
            width: 100%;

        }
    }

    .content.hide_side {
        width: 100%;
    }

    .bold {
        font-weight: bolder;

    }

    .size-lg {
        letter-spacing: 0.3rem;
        font-size: 35px;
        line-height: 45px;
        color: #000;

        @media only screen and (max-width: 767px) {
            font-size: 25px;
            line-height: 35px;
        }
    }

    .size-sm {
        font-size: 1.2rem;
    }

    .flex {
        display: flex;
        flex-direction: column;
        gap: .5rem
    }

    .flex input[type="text"] {
        width: 25rem;
        height: 2.5rem;
        padding-left: .5rem;
        border-radius: .3rem;
        border: .5px solid;
    }

    .flex textarea {
        width: 25rem;
        height: 6rem;
        padding-left: .5rem;
        border-radius: .3rem;
        border: .5px solid;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        gap: 4rem;
    }

    .gap-1 {
        gap: 1rem;
    }


    .dropdown {
        display: flex;
        flex-direction: row;
        width: 15rem;
        height: 42px;

    }

    .gpx {
        position: relative;
    }

    .gpx input {
        position: absolute;
        opacity: 0.000000001;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        cursor: pointer;
    }

    // .dropdown select{
    //     appearance: none;
    //     -webkit-appearance: none;
    //     -moz-appearance: none;
    //     width: 100%;
    //     height: auto;
    //     background-image: url('../images/icons/arrow-down.png');
    //     background-repeat: no-repeat;
    //     background-position: right center;
    //     background-size: 28px 25px; 
    //     padding-left:.5rem ;
    //     border-radius: .3rem;
    //     border: .5px solid;
    // }

    .btn-save {
        width: auto;
        height: auto;
        padding: .5rem 1rem;
        color: black;
        background-color: orange;
        border-radius: 3px;
        border: none;
    }

    .btn-discard {
        width: auto;
        height: auto;
        padding: .5rem 1rem;
        color: white;
        font-weight: 400;
        background-color: rgba(213, 11, 11, 0.923);
        border-radius: 3px;
        border: none;
    }

    .btn-discard:hover {
        background-color: rgba(213, 11, 11, 0.777);
    }




    .content section {
        width: 100%;
        height: 100%;


    }

    .main-content {
        min-height: calc(100vh - 98px);
        background-color: #fff;
        border-radius: 5px;
        padding: 15px 20px;
        box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    }

    .info-text {
        color: gray;
        font-size: medium;
        margin-top: -1rem;

    }

    .srv-validation-message {
        color: #ff0000 !important;
        font-size: 12px;
        margin-top: 4px;
    }

    .file-container {
        width: 100%;
        height: auto;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }


}

/** btn style  **/
button {

    &:hover,
    &:focus,
    &:active {
        box-shadow: none !important;
        outline: 0 !important;
    }

}

.btn {
    display: inline-block;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    line-height: 24px;
    padding: 8px 16px;
    font-size: 14px;
    letter-spacing: 0.01em;
    border-radius: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    transition: all, 0.4s, ease-in-out;
    min-width: 100px;

    &.btn-sm {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem
    }

    &:focus,
    &:hover,
    &:active {
        box-shadow: none;
        outline: none;
    }
}

.btn-dark {
    background-color: #000;
    border-color: #000;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
        background-color: #fff;
        border-color: #000;
        color: #000;
    }

}

.btn-light {
    color: #353535;
    background-color: #d8dbe2;
    border-color: #d8dbe2;

    &:hover,
    &:focus,
    &:active {
        background-color: #bbc0cc;
        border-color: #bbc0cc;
        color: #353535;
    }
}

.btn-container {
    margin: -5px;

    .btn {
        margin: 5px;
    }
}

.modal-dialog {
    .modal-content {
        border-radius: 0;

        .modal-header {
            color: #fff;
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            padding: 15px 15px;
            border-bottom: 1px solid #ebebeb;
            background-color: #000;

            .modal-title.h4 {
                font-weight: 500;
                font-size: 18px;
                letter-spacing: 0.1px;
                color: #fff;
                font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
            }
        }
    }

    .modal-footer {

        //   padding-bottom: 0px;
        button {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    }
}


//switch style

.switch {
    border-radius: 30px;
    width: 50px;
    min-width: 50px !important;
    height: 25px;
    min-height: 25px !important;
    background-color: $white;
    border: 2px solid #ccc;
    border-color: #ccc !important;

    .switch-group {
        width: 100%;
        height: 25px;
        background-color: $white;
        border-color: #ccc !important;
    }

    .switch-handle {
        background: #fff;
        border-radius: 50%;
        border-width: 0;
        height: 20px;
        padding: 1px;
        vertical-align: initial;
        width: 20px;
        min-width: auto;
    }

    .switch.off .switch-group {
        left: 0;
    }

    .switch-off {
        width: 50px;
        height: 25px;
        background-color: #ccc;
        left: 100%;
        font-size: 0px;
    }

    .switch.btn.off .switch-off {
        background: #ccc;
    }

    .switch-on {
        color: transparent;
        width: 100%;
        left: 0px;
        right: unset;
        font-size: 0px;
        background-color: gray;
    }

    .switch.btn.on .switch-group {
        right: unset;
        background: #30e33c;
    }

    .switch.btn.off .switch-group {
        background-color: gray;
    }
}

.switch.btn.off .switch-handle {
    transform: translate(34px, 0.6px);
}

.switch.btn.on .switch-handle {
    transform: translate(12px, 2.5px);
}

.switch.btn.on .switch-on.btn {
    background: $black;
}

.switch-off.btn {
    padding-left: 0px !important;
}

.switch.btn.on {
    border: 0px solid $black;
    border-color: $black !important;
}


/** btn style  **/
.breadcrumb {
    background-color: $bg-secondry-color;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        color: $heading-color;
        margin: 0;
        font-size: 20px;
        font-weight: 500;
    }

    ul {
        display: flex;
        margin: 0;

        li {
            margin-right: 36px;
            position: relative;

            a {
                font-weight: 500;
                font-size: 14px;
                color: $heading-color;
                text-decoration: none;

                svg {
                    font-size: 16px;
                    vertical-align: top;
                    margin-top: 3px;
                    color: $hover-secondry;
                }
            }

            &::after {
                content: "";
                position: absolute;
                height: 8px;
                width: 8px;
                border-top: 1px solid #9e9e9e;
                border-right: 1px solid #9e9e9e;
                top: 7px;
                right: -20px;
                transform: rotate(45deg);
            }

            &:last-child {
                margin-right: 0;

                &::after {
                    display: none;
                }
            }
        }

        li.active {
            color: #9e9e9e;
        }
    }
}

.table-box {
    background-color: $bg-secondry-color;
    border-radius: 8px;

    .table-top-scrolling {
        th {
            border-bottom: 1px solid #000;
        }
    }
}

.imagePage {
    background-color: #fff;
    height: calc(100vh - 40px);
}

.table_scroll {
    overflow-x: auto;
}

.pagin_group {
    margin-top: 35px;
    display: flex;
    justify-content: space-between;

    .show_reslts {
        min-width: 160px;

        select {
            border: 1px solid #e4e4e4;
            border-radius: 5px;
            height: 40px;
            width: 100%;
            font-size: 14px;
            background-size: 9px;
            cursor: pointer;
            font-weight: 400;
        }
    }

    .paginations {
        ul {
            display: flex;

            li {
                margin-right: 20px;
                margin-bottom: 5px;

                button {
                    border: 1px solid #e4e4e4;
                    width: 50px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 5px;
                    text-align: center;
                    color: rgba(16, 20, 29, 0.82);
                    font-size: 15px;
                }

                &.pagi_actived {
                    button {
                        background-color: #1D2858;
                        border-color: #1D2858;
                        color: #ffffff;
                    }
                }
            }

        }
    }
}

.table {
    thead {
        th {
            font-weight: 700;
            font-size: 14px;
        }
    }

    td {
        font-weight: 400;
        font-size: 14px;
    }
}

.site-card {
    padding: 16px 26px;
    border-color: rgba(122, 133, 129, 0.2);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 100%;

    @media only screen and (max-width: 767px) {
        padding: 16px 16px;
    }
}

.site-hr {
    margin-bottom: 25px;
    margin-top: 30px;
    border-top: 1px solid #ccc;
}

.top-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .size-lg {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 767px) {
        align-items: flex-start;
    }

    @media only screen and (max-width: 320px) {
        flex-wrap: wrap;
        gap: 15px;
    }
}

.upload-form-btn-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 20px;

    @media only screen and (max-width: 767px) {
        gap: 10px;
        flex-direction: column;
    }
}

.info-text {
    margin-bottom: 20px;
}


.map-main-wrapper {
    width: 100%;
    margin-top: 5px;

    .file-list-wrapper {
        display: inline-block;
        width: 350px;
        vertical-align: top;
        padding-right: 10px;
        @media only screen and (max-width: 1199px) {
            width: 300px;
        }

        @media only screen and (max-width: 991px) {
            width: 100%;
            padding-right: 0;
            margin-bottom: 15px;
        }

        .file-container {

            margin-top: 0;
            height: calc(75vh - 100px);
            overflow: auto;
            padding: 0 7px 0 0;

            @media only screen and (max-width: 767px) {
                height:auto;
                max-height: 350px;
            }
            .files {
                padding-bottom: 10px;
                background-color: #d8dbe2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                position: relative;
                border: 1px solid transparent;

                .meta-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    align-items: initial;
                    padding-bottom: 10px;
                    width: 70%;
                }

                .files-name {
                    background-color: transparent;
                    border-radius: 0;
                    font-size: 14px;
                    font-weight: bold;
                    padding: 0;
                    text-align: left;
                    /* width: 200px;*/
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .files-info {
                    p {
                        font-weight: 400;
                        font-size: 14px;
                        color: #000;
                        margin-bottom: 0;
                        margin-top: 0;
                    }
                }

                .files-download {

                    button,
                    a {
                        text-align: center;
                        width: 55px;
                        height: auto;
                        padding: 0 0.4rem;
                        border-radius: 5px;
                        background-color: #fff;
                        margin-top: 4px;
                        border: 1px solid #fff;
                        font-size: 14px;
                        color: #000;
                        text-transform: capitalize;
                        text-decoration: none;
                        word-break: break-word;
                    }
                }
            }
        }
    }

    .map-wrapper {
        display: inline-block;
        width: calc(100% - 350px);

        @media only screen and (max-width: 1199px) {
            width: calc(100% - 300px);
        }

        @media only screen and (max-width: 991px) {
            width: 100%;
        }
    }
    &.map-main-wrapper-new {
        @media only screen and (max-width: 991px) {
           display: flex;
           justify-content: space-between;
        }
        @media only screen and (max-width: 767px) {
           margin-top: 0;
           flex-direction: column;
         }
        .file-list-wrapper {
            @media only screen and (max-width: 991px) {
                width: auto;
            }
        }
        .map-wrapper {
            @media only screen and (max-width: 991px) {
                width: auto;
            }
        }
        .dropdown-link-wrapper {
            @media only screen and (max-width: 991px) {
               margin-bottom: 0;
               gap: 15px;
            }
        }
    }
}

.dateRang {

    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
    .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
    .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
        top: 12px;

    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: $black;
    }

    .rs-picker-toggle .rs-picker-toggle-placeholder {
        color: #424242;
        padding-right: 13px;
    }
}

.dateRang .rs-picker-daterange {
    width: 100%;

    .rs-picker-toggle {
        height: 45px;
        border: 1px solid #818e9b;
        padding: 10px 16px;
        box-shadow: none;
        outline: 0;

        &:hover,
        &:focus,
        &:active {
            border-color: #000;
        }
    }

    input {
        height: 45px;
        width: 100%;
        border: 0;
        box-shadow: none;
        padding-left: 16px;
    }

    .rs-stack-item {
        margin-top: 0;
    }
}

.btn-wrapper {
    display: flex;
    gap: 15px;
}

.about-wrapper {
    text-align: center;
    background-color: #f5f5f5;
    padding: 30px 16px;
    margin-bottom: 30px;
    margin-top: 10px;

    .upload-form-btn-wrapper {
        margin-bottom: 15px;
        justify-content: center;
    }

    .info-text {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.range-value-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 2px;
    justify-content: space-between;

    p {
        font-size: 14px;
        border: 1px solid #ccc;
        padding: 5px;
        display: inline-block;
        line-height: 15px;
        margin: 0;
        text-transform: capitalize;
        background-color: #fff;
    }
}

div.react-confirm-alert-overlay {
    .confirmModel {
        width: 300px;
        text-align: center;

        .modelHeader {
            display: none;
        }

        .commonModelFooter {
            padding: 15px 15px 15px 15px;
            border-top: 0;
            text-align: center;
            display: flex;
            justify-content: center;
        }

        .modelBody {
            padding: 20px 15px 10px 15px;

            p {
                color: #000;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
            }
        }

        button.MuiButtonBase-root {
            min-width: 88px;
        }

        button.MuiButton-containedPrimary {
            background-color: #000;
            border-color: #000;
            color: #fff;
        }

        button.MuiButton-outlinedPrimary {
            background-color: #d8dbe2;
            border-color: #d8dbe2;
            color: #353535;
        }
    }
}

.arrow-with-title {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
        gap: 10px;
    }

    .btn {
        min-width: 76px;

        svg {
            margin-right: 5px;
        }
    }
}

.site-modal {
    .modal-footer {
        justify-content: center;
    }
}

.elevator {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0;
    padding: 5px 0;
    label:nth-child(even) {
        text-align: right;
    }

    label {
        width: 50%;
        font-size: 12px;
        margin: 0;

        span {
            display: inline-block;
            font-weight: bold;

            p {
                margin: 0;
            }
        }
    }

}

.arrowDown {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: -5px;
    left: 0px;
    background: transparent;
    cursor: pointer;
    border: 1px solid #424242;

    &.arrowUp {
        &::after {
            transform: translate(5px, 7px) rotate(135deg);
        }
    }

    &::after {
        content: "";
        position: absolute;
        background: transparent;
        padding: 0;
        width: 8px;
        height: 8px;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        transition: all ease-in-out 0.3s;
        left: 0;
        top: 0;
        transform: translate(5px, 3px) rotate(-45deg);
    }
}

.fileContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    min-height: 58px;
}

.customWindow {
    h4 {
        background-color: transparent;
        border-radius: 0;
        font-size: 15px;
        font-weight: 300;
        padding: 0;
        text-align: left;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
    }
}

.arrowLeft {
    padding: 0;
    background: #000;
    color: #fff;
    border-radius: 0 15px 15px 0;
    height: 26px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 170px;
    z-index: 99;

    &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        left: 6px;
        top: 8px;
        transform: rotate(45deg);
        
    }
}

.arrowRight {
    padding: 0;
    background: #000;
    color: #fff;
    height: 26px;
    width: 20px;
    position: absolute;
    border-radius: 15px 0 0 15px;
    right: 0;
    top: 170px;
    z-index: 99;

    &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        right: 6px;
        top: 8px;
        transform: rotate(-135deg);
    }
}

.fileContainer.mqContainer {
    .meta-container {
        padding-bottom: 0 !important;
    }
    height: 72px;
}
.fileContainer.mqContainer.isbutton {
    height: 83px;
}
.fileContainer.mqContainer.noButton {
    align-items: center;
}
.trackList {
    height: calc(100vh - 400px);
}

.about-heading-wrapper {
    flex-wrap: wrap;

    .btn-wrapper {
        flex-wrap: wrap;
    }
}

.map_details_wrapper {
    flex-wrap: wrap;
}

.small_map {
    width: 100%;
    height: 240px;
}

.table p {
    margin-bottom: 0;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #16181b;
    background-color: #e9ecef;
}

.css-xx9id5 {
    width: 100%;
    max-width: 400px;
}

.dropdown-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: 10px;
    }
    .dropdown-wrapper {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: auto;
    }

    .text-sort {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0.375rem 0.75rem;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        text-align: center;
        white-space: nowrap;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;

    }

    .dropdown {
        position: relative;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0 10px;
        min-width: 135px;
    }

    button.dropdown-toggle {
        background-color: transparent !important;
        color: #000 !important;
        display: inline-block;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        border: 0;
        line-height: 24px;
        padding: 0px !important;
        font-size: 14px;
        letter-spacing: 0.01em;
        border-radius: 0px;
        cursor: pointer;
        height: auto;
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        transition: all, 0.4s, ease-in-out;
        min-width: 100px;
        border-color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:hover,
        &:focus,
        &:active {
            background-color: transparent !important;
            color: #000 !important;
            box-shadow: none !important;
            outline: 0 !important;
        }

        svg {
            margin-left: 10px;
        }
    }
}
.multi-select {
    .searchWrapper {
        min-height: 45px;
        border: 1px solid #818e9b;
        background: #fff;
        padding: 5px 4px 5px 10px
    }
}
.crooss {
    top: 0;
    position: absolute;
    right: 10px;
    font-size: 1.5rem;
    z-index: 1;
    cursor: pointer;
}
.customWindow {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 8px -3px #000;
    width: 320px;
    position: absolute;
    border: 1px solid #000;
    overflow: hidden;
    z-index: 9999;
}
.sortDropDown .dropdown-menu {
    left: -11px !important;
    width: 136px !important;
    min-width: auto !important;
    a {
        padding: 0.25rem 10px;
        font-size: 14px;
    }
}
input#search_input[placeholder=""] {
    display: none;
}
.NoData {
    position: relative;
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        color: #666;
        font-size: 20px;
    }
}

@media only screen and (max-width: 1399px) and (min-width: 768px)  { 
    .fillter-wrapper .filter-right { 
        margin-top: 0;
        position: absolute;
        bottom: 20px;
        right: 0;
    }
}



.view-btn-wrapper {
    display: flex;
    align-items: center;
    button {
        white-space: nowrap;
        border: 1px solid #000;
        line-height: 24px;
        font-size: 14px;
        letter-spacing: 0.01em;
        position: relative;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        min-width: 100px;
        border-radius: 0;
        cursor: pointer;
        display: block;
        text-decoration: none;
        color: #333;
        flex-grow: 3;
        background-color: #fff;
        -webkit-user-select: none;
        user-select: none;
        text-align: center;
        transition: 0.3s background-color ease, 0.3s box-shadow ease;
        height: 42px;
        box-sizing: border-box;
        padding: 9px 15px;
        text-transform: capitalize;
        font-weight: 500;
       &.active{
        font-weight: 600;
        background-color: #000;
        color: #fff;
        border-color: #000;
       }
    }
}

.text-sort  {
    svg {
        margin-left: 5px;
    }
    &.uparrow {
        svg {
            transform: rotate(180deg);
        }
    }
}
.durationDistance {
    p, span {
        font-weight: bold !important;
        margin: 0 !important;
        display: inline-block !important;
        font-size: 14px !important;
        width: auto !important;
        background-color: transparent !important;
    }
}
.activityType {
    margin: 3px 0 0 0 !important;
    font-size: 13px !important;
    font-weight: bold !important;
}
.mapDiv {
    position: relative;
    height: calc(75vh - 100px);
    .activeImage {
        position: absolute;
        right: 56px;
        bottom: 23px;
        z-index: 99999;
        width: 339px;
    }
}
.datePicker {
    .input_modify {
        padding: 3px;
    }
    .rs-picker-toggle.rs-btn.rs-btn-default {
        border: 0;
        box-shadow: none;
    }
}
.deleteButton {
    position: absolute;
    right: -8px;
    top: -8px;
    height: calc(100% + 17px);
    padding: 0;
    z-index: 99;
    svg {
        font-size: 17px;
        margin: 0 2px;
        color: #f00;
    }
}

.rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-bottom-start {
    z-index: 99999;
}
.files-name {
    .editDate {
        font-size: 18px;
        margin: 1px 0 0px 9px;
        vertical-align: top;
        position: relative;
        z-index: 99;
        border-radius: 3px;
        color: #000;
    }
}
.editDatePicker span.rs-picker-toggle-value {
    color: #000 !important;
}
#mapBox {
    height: calc(75vh - 100px);
    width: 100%;
}
.mainMapContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(75vh - 100px);
}

// body:after {
//     content: "";
//     position: fixed;
//     top: 0;
//     left: 50%;
//     height: 50px;
//     width: 20px;
//     background: linear-gradient(#fe9300, #fe930085 40px);
//     z-index: 99999;
// }

// body:before {
//     content: "";
//     position: fixed;
//     top: 50px;
//     left: 50%;
//     height: 50px;
//     width: 20px;
//     background: linear-gradient(#fe9300, #fe930085 40px);
//     z-index: 99999;
//     transform: rotate(180deg);
// }
.optionListContainer {
    z-index: 99999 !important;
}
.rs-picker-menu {
    z-index: 99999;
}