#dropdown-basic {
    &::after {
        display: none;
    }
}
.header {
    background-color: $bg-secondry-color;
    display: flex;
    flex-wrap: nowrap;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    .header_logo {
        width: 260px;
        display: flex;
        justify-content: space-between;
        padding: 9px 24px;
        align-items: center;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 9px 15px;
        }
        .logo {
            width: 100%;
            text-align: left;
            font-size: 36px;
            font-weight: 600;
            img {
                width: 100%;
            }
        }
        .header_menu {
            button {
                padding: 0 !important;
                width: 34px;
                height: 34px;
                min-width: 34px !important;
                border-radius: 8px;
                background: #000;
                color: #fff;
                &:hover {
                    background: #000;
                    color: #fff;
                }
            }
        }        
    }
    .header_bar {
        width: calc(100% - 260px);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 9px 24px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 9px 15px;
        }
        .toggle_btn {
            background: $bg-secondry-color;
            color: $paragragh-color;
            padding: 0;
            border: 0;
            &:hover {
                background: $bg-secondry-color;
                color: $paragragh-color;
            }
            &:focus {
                box-shadow: none !important;
            }
            &:active {
                background: $bg-secondry-color;
                box-shadow: none;
            }
            .header_drop {
                color: #2A3941;
                font-size: 14px;
                line-height: 40px;
                span {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    color: #ffffff;
                    background: #000;
                    border-radius: 5px;
                    font-size: 14px;
                    text-transform: uppercase;
                    text-align: center;
                    margin-right: 13px;
                }
            }
        }
    }
}
.header_menu {
    display: none;
    @media only screen and (max-width: 767px) {
        display: block;
    }
}