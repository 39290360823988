.side {
    nav.MuiList-root {
        padding: 16px;
    }
    .MuiList-root {
        padding: 0;
        .MuiListItemText-root {
            span {
                font-size: 14px;
                color: #2A3941;
            }
        }
        a {
            color: $paragragh-color;
            &:hover {
                text-decoration: none;
            }
        }
        .MuiListItemButton-root.active {
            background-color: #000;
           span {
            color: #fff;
           }
           .css-cveggr-MuiListItemIcon-root {
                 
            svg {
              path {
                  fill: #fff;
              }
            }
              
          }
        }
        .MuiListItemButton-root {
            border-radius: 8px;
            margin-bottom: 4px;
            &:hover {
                background-color: #000;
                span {
                    color: #fff;
                }
                .css-cveggr-MuiListItemIcon-root {
                 
                    svg {
                      path {
                          fill: #fff;
                      }
                    }
                      
                  }
            }
            .MuiListItemIcon-root {
                min-width: 42px;
            }
            .css-cveggr-MuiListItemIcon-root {
                 
              svg {
                path {
                    fill: rgba(0, 0, 0, 0.54);
                }
              }
                
            }
           
        }
    }
}