label {
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.01em;
  padding-bottom: 5px;
  color: #424242;
  padding-top: 0;
  font-family: "Poppins", sans-serif !important;
  padding-bottom: 0;
}

.input_modify {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  padding: 10px 16px;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 159.3%;
  display: block;
  color: #424242;
  border: 1px solid #818e9b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus,
  &:active {
    border-color: #000;
    outline: 0;
    box-shadow: none;
  }
}

.select_modify {
  min-height: 30px;
  appearance: none;
  background-image: url("../images/icons/icn-down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 11px;
  background-position: right 10px center;
  padding: 8px 10px;
  padding-right: 20px;
}

select {
  text-indent: 0px;
}

textarea {
  height: 100px !important;
}


.tag-list {

  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  input {
    margin-right: 100px;
  }


  .tag-container {
    label {
      float: left;
      margin-bottom: 0;
      cursor: pointer;

      span {
        text-align: center;
        padding: 10px 15px;
        display: block;
        border: 1px solid #818e9b;
        color: #000;
        font-size: 15px;
        font-weight: 400;
      }

      input {
        position: absolute;
        display: none;

      }
    }
  }




  .tag-container input:checked+span {
    color: #ffffff;
    background-color: #000;

  }
}



.fillter-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  column-gap: 35px;
  position: relative;
  @media only screen and (max-width: 1399px) {
    column-gap: 0;
    flex-direction: column;
    align-items: self-start;
  }

  .tool {
    display: block;
    width: 100%;
  }

  .filter-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 1 1;
    gap: 15px;

    @media only screen and (max-width: 1399px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .filter-right {
    display: flex;
    gap: 15px;
    margin-top: 22px;

    @media only screen and (max-width: 1199px) {
      margin-top: 0;
    }
  }
}